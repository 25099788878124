<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="6">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="`${i} Title`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Title`">
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="`${i} Title`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="`${i} Description`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Description`">
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="`${i} Description`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12" class="my-2">
              <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Image</legend>
              <ValidationProvider
                  v-slot="{ errors }"
                  vid="image"
                  name="Image"
              >

                <MediaLibraryAttachment
                    ref="mediaComponent"
                    :initial-value="media_image"
                    :max-items="1"
                    name="image"
                    :headers="headers"
                    routePrefix="media-library"
                    :uploadDomain="baseURL"
                    @change="getFiles($event)"
                    :validation-rules="{ accept:['image/svg+xml'], maxSizeInKB: 2048 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                Status
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { baseURL } from '@/services/url.service'
import MediaLibraryAttachment from '../../components/media-spatie/media-library-pro-vue2-attachment/src/MediaLibraryAttachment.vue'

export default {
  mixins: [formMixin],
  components: {
    MediaLibraryAttachment,
  },
  data() {
    return {
      ratings: [],
      baseURL: baseURL(),
      imageAfterUpload: [],
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      validationErrors: {
        image: 'The Image field is required',
      },
      media_image: null,
      image_uuid: null,
      form: {
        title: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        image_uuid: null,
        status: 'active',
      },
      form_data: null,
    }
  },
  watch: {
    image_uuid(val) {
      if (val) {
        this.form.image_uuid = val
      } else {
        this.form.image_uuid = 'delete'
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getObj(el) {
      return { 0: el }
    },
    getFiles(e) {
      const arr = []
      arr.push(e)
      this.imageAfterUpload = arr
      this.form.image_uuid = Object.keys(this.imageAfterUpload[0])[0]
      // this.form.avatar = Object.keys(this.imageAfterUpload[0])
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title_value,
            description: data.description_value,
            status: data.status,
            image_uuid: data.media_image?.uuid || null,
          }
          this.media_image = data.media_image
          this.image_uuid = data.media_image?.uuid || null

          let arr = []
          const obj = {
            attributes: this.media_image,
            clientValidationErrors: [],
            client_preview: data.media_image?.original_url,
            upload: {},
          }
          arr.push(obj)
          this.$refs.mediaComponent.mediaLibrary.changeState(state => {
            state.media = arr
          })
        })
    },
    loadFormData() {
      const $form = _.cloneDeep(this.form)
      this.form_data = $form
      if (this.isEdit) {
        // if (!($form.avatar instanceof File) && $form.avatar !== 'delete') {
        //   delete $form.avatar
        // }
        // eslint-disable-next-line no-unused-vars
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },
}
</script>
